import React, { useState, useEffect } from 'react';
import { parse } from 'query-string';
import * as styles from './shop.module.css';

import Accordion from '../components/Accordion';
import Checkbox from '../components/Checkbox';
import Banner from '../components/Banner';
import Breadcrumbs from '../components/Breadcrumbs';
import CardController from '../components/CardController';
import Container from '../components/Container';
import Chip from '../components/Chip';
import Icon from '../components/Icons/Icon';
import Layout from '../components/Layout';
import LayoutOption from '../components/LayoutOption';
import ProductCardGrid from '../components/ProductCardGrid';
import { filterProductData } from '../helpers/mock';
import Button from '../components/Button';
import Config from '../config.json';

const ShopPage = (props) => {
  const params = parse(props.location.search);
  const filterQuery = params.c ? params.c : 'powder';
  console.log(filterQuery);

  const data = filterProductData(filterQuery);
  const filters = Config.filters;

  const [filterState, setFilterState] = useState(filters);

  const filterTick = (e, categoryIndex, labelIndex) => {
    const filterStateCopy = [...filterState];
    filterStateCopy[categoryIndex].items[labelIndex].value = !e.target.value;
    setFilterState(filterStateCopy);
  };

  const shopTitle =
    filterQuery === 'spices'
      ? 'Spice powders'
      : filterQuery === 'health'
      ? 'Health powders'
      : 'Spices and Health mix';

  return (
    <Layout>
      <div className={styles.root}>
        <Banner
          maxWidth={'650px'}
          name={shopTitle}
          subtitle={
            'Our Spices and Health Mix products are hygienically packed to ensure better shelf life.'
          }
        />
        <Container size={'large'} spacing={'min'}>
          <div className={styles.content}>
            <div>
              <ProductCardGrid height={'440px'} data={data}></ProductCardGrid>
            </div>
          </div>
        </Container>
      </div>
      <LayoutOption />
    </Layout>
  );
};

export default ShopPage;
